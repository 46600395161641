var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cupertino-alert-dialog", {
    attrs: { title: _vm.title, close: _vm.onClickOutSide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", {
              staticClass: "px-1 py-1",
              domProps: { innerHTML: _vm._s(_vm.content) },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full border-right-1 flex flex-center pointer py-1 hover left-bottom-radius-10 font-bold",
                    on: { click: _vm.onCancelClick },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.cancel))])]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover right-bottom-radius-10 font-bold",
                    on: { click: _vm.onChangeClick },
                  },
                  [
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v(_vm._s(_vm.change)),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }