var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog bg-white flex flex-column",
          style: { width: `${_vm.width}px` },
        },
        [
          _c(
            "div",
            {
              staticClass: "title flex flex-center flex-column",
              style: { fontSize: _vm.fontSize },
            },
            [
              _c("h3", { class: { error: _vm.isError } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._t("header"),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "flex-1 overflow-auto" },
            [
              _vm.content
                ? _c("span", { staticClass: "px-1 pb-1" }, [
                    _vm._v(" " + _vm._s(_vm.content) + " "),
                  ])
                : _vm._t("content"),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "aciton" },
            [
              _vm.action
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-full flex flex-center pointer py-1 hover bottom-radius-10",
                      on: { click: _vm.onClickOutSide },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.action))])]
                  )
                : _vm._t("actions"),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }